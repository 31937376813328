import {environment} from '../../../../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { PostcodeLookupServiceInterface } from '../postcode-lookup-service-interface';
import { SessionService } from '../../session.service';

@Injectable()
export class GetAddressProvider implements PostcodeLookupServiceInterface {
    private base_url: string = 'https://api.getaddress.io';
    private api_url: string = environment.api_url;

    constructor(private http_client: HttpClient, private session_service: SessionService) {}

    searchAddress(search_term: string): Observable<any> {
        const headers = new HttpHeaders({
            Domain: this.session_service.domain,
        });

        const url = new URL(`${this.api_url}core/postcode-lookup/get-suggested-addresses/${search_term}`);
        const response = this.http_client.get(url.toString(), {headers: headers});

        return response;
    }

    getAddressById(id: string): Observable<any> {
        const headers = new HttpHeaders({
            Domain: this.session_service.domain,
        });

        const url = new URL(`${this.api_url}core/postcode-lookup/get-address/${id}`);
        const response = this.http_client.get(url.toString(), {headers: headers});

        return response;
    }

    formatSuggestedAddresses(suggestedAddresses): string[] {
        return suggestedAddresses.suggestions.map((item) => {
            return {
                id: item.id,
                text: item.address,
            };
        });;
    }

    formatAddress(address): any {
        return {
            line1: address.residential == false ? address.line_2 :  address.line_1,
            line2: address.residential == false ? address.line_3 : address.line_2,
            line3: address.residential == false ? null : address.line_3,
            city: address.town_or_city,
            county: address.county,
            postcode: address.postcode,
            company: address.residential == false ? address.line_1 : null,
            subBuilding: address.sub_building_name ?? null,
            buildingNumber: address.building_number ?? null,
            buildingName: address.building_name ?? null,
            street: address.thoroughfare ?? null,
        };
    }
}
