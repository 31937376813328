import { Injectable, Injector } from '@angular/core';
import { LoqateProvider } from './providers/loqate.provider';
import { PostcodeLookupServiceInterface } from './postcode-lookup-service-interface';
import { GetAddressProvider } from './providers/get-address.provider';

@Injectable()
export class PostcodeLookupSericeFactory {
  private providers = {
    loqate: LoqateProvider,
    getAddress: GetAddressProvider,
  };

  constructor(private injector: Injector) {}

  getProvider(providerName): PostcodeLookupServiceInterface {
    const providerClass = this.providers[providerName];

    if (!providerClass) {
      throw new Error(`Provider '${providerName}' not found`);
    }

    return this.injector.get(providerClass);
  }
}
