import * as CryptoJS from 'crypto-js';

export class EncryptionHelper {
	static encrypt(text: string, secretKey: string = null): string {
		if (secretKey === null) {
			secretKey = text;
		}

		return CryptoJS.AES.encrypt(text, secretKey).toString();
	}
}
