import {environment} from '../../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { PostcodeLookupServiceInterface } from '../postcode-lookup-service-interface';

@Injectable()
export class LoqateProvider implements PostcodeLookupServiceInterface {
    private base_url: string = 'https://services.postcodeanywhere.co.uk/';

    constructor(private http_client: HttpClient) {}

    searchAddress(search_term: string): Observable<any> {
        let params: HttpParams = new HttpParams();
        params = params.append('key', environment.loqate_api_key);
        params = params.append('searchterm', search_term);

        return this.http_client.post(this.base_url + 'CapturePlus/Interactive/Find/v2.10/json3.ws', null, {
            params,
            observe: 'response',
        });
    }

    getAddressById(id: string): Observable<any> {
        let params: HttpParams = new HttpParams();
        params = params.append('key', environment.loqate_api_key);
        params = params.append('id', id);

        return this.http_client.post(this.base_url + '/CapturePlus/Interactive/Retrieve/v2.10/json3.ws', null, {
            params,
            observe: 'response',
        });
    }

    formatSuggestedAddresses(response): string[] {
        return response.body.Items.map((item) => {
            return {
                id: item.Id,
                text: item.Text,
            };
        });
    }

    formatAddress(response): any {
        const address = response.body.Items[0];
        return {
            line1: address.Line1,
            line2: address.Line2,
            line3: address.Line3,
            city: address.City,
            county: address.Province,
            postcode: address.PostalCode,
            company: address.Company,
            subBuilding: address.SubBuilding ?? null,
            buildingNumber: address.BuildingNumber ?? null,
            buildingName: address.BuildingName ?? null,
            street: address.Street ?? null,
        };
    }
}
