import { Injectable } from '@angular/core';
import { PostcodeLookupServiceInterface } from './postcode-lookup-service-interface';
import { PostcodeLookupSericeFactory } from './postcode-lookup-service-factory';

@Injectable()
export class PostcodeLookupService {
  private provider: PostcodeLookupServiceInterface;

  constructor(private postcodeLookupFactory: PostcodeLookupSericeFactory) {
  }

  setProvider(provider: string) {
    this.provider = this.postcodeLookupFactory.getProvider(provider);
  }

  searchAddress(search_term: string) {
    return this.provider.searchAddress(search_term);
  }
  
  getAddressById(id: string) {
    return this.provider.getAddressById(id);
  }
  
  formatSuggestedAddresses(suggestedAddresses: string[]) {
    return this.provider.formatSuggestedAddresses(suggestedAddresses);
  }
  
  formatAddress(address: string[]) {
    return this.provider.formatAddress(address);
  }
}
