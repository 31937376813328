import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APIUtilityService } from '@app/core/services/api-utility.service';
import { APIService } from '@app/core/services/api.service';

import { HttpHeaders } from '@angular/common/http';
import { EncryptionHelper } from '@app/core/helpers/encryption.helper';

@Injectable()
export class ConfigureAPIService {
	constructor(private api_service: APIService, private api_utility_service: APIUtilityService) {}

	/**
	 * Returns vehicle details.
	 *
	 * @param {number} vehicle_id
	 * @returns {Observable}
	 */
	getVehicle(vehicle_id: number): Observable<any> {
		return this.api_utility_service.intercept(this.api_service.request('get', 'configure/vehicle/' + vehicle_id));
	}

	/**
	 * Creates new enquiry with customer details.
	 *
	 * @param {number} vehicle_id
	 * @param body
	 * @returns {Observable}
	 */
	createEnquiry(vehicle_id: number, body: any): Observable<any> {
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Authorization', 'Bearer ' + EncryptionHelper.encrypt(body.customer.email));

		return this.api_utility_service.intercept(
			this.api_service.postOrPutRequest(
				'post',
				'configure/vehicle/' + vehicle_id + '/enquiry',
				body,
				null,
				headers
			)
		);
	}

	/**
	 * Gets enquiry details.
	 *
	 * @param {string} enquiry_hash
	 * @param {string} step_mode
	 *
	 * @returns {Observable}
	 */
	getConfigureData(enquiry_hash: string, step_mode: string = null, vehicle_id: number = null): Observable<any> {
		let authenticate_path = ['/configure/e', enquiry_hash, 'authenticate'];
		let query_params = null;

		if (vehicle_id !== null) {
			authenticate_path = ['/configure/v', vehicle_id.toString(), 'p', enquiry_hash, 'authenticate'];
			query_params = { pex_enquiry_hash: enquiry_hash };
		}

		return this.api_utility_service.intercept(
			this.api_service.request(
				'get',
				'configure/enquiry/' + enquiry_hash + '/steps' + (step_mode ? '?step_mode=' + step_mode : ''),
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			authenticate_path,
			false,
			false,
			query_params
		);
	}

	/**
	 * Creates part exchange vehicle.
	 *
	 * @param {string} enquiry_hash
	 * @param body
	 * @returns {Observable}
	 */
	createPartExVehicle(enquiry_hash: string, body: any): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.postOrPutRequest(
				'post',
				'configure/enquiry/' + enquiry_hash + '/steps/part-exchange',
				body,
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/configure/e', enquiry_hash, 'authenticate'],
			true
		);
	}

	/**
	 * Updates part exchange vehicle.
	 *
	 * @param {string} enquiry_hash
	 * @param body
	 * @returns {Observable}
	 */
	updatePartExVehicle(enquiry_hash: string, body: any): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.postOrPutRequest(
				'put',
				'configure/enquiry/' + enquiry_hash + '/steps/part-exchange',
				body,
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/configure/e', enquiry_hash, 'authenticate'],
			true
		);
	}

	/**
	 * Deletes part exchange vehicle.
	 *
	 * @param {string} enquiry_hash
	 * @returns {Observable}
	 */
	deletePartExVehicle(enquiry_hash: string): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.request(
				'delete',
				'configure/enquiry/' + enquiry_hash + '/steps/part-exchange',
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/configure/e', enquiry_hash, 'authenticate']
		);
	}

	/**
	 * Saves and re-builds deal.
	 *
	 * @param {string} enquiry_hash
	 * @param body
	 * @returns {Observable}
	 */
	saveCustomiseReview(enquiry_hash: string, body: any): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.postOrPutRequest(
				'put',
				'configure/enquiry/' + enquiry_hash + '/steps/customise-review',
				body,
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/configure/e', enquiry_hash, 'authenticate']
		);
	}

	/**
	 * Deletes all current finance quotes
	 *
	 * @param enquiry_hash
	 * @returns {Observable}
	 */
	deleteFinanceQuotes(enquiry_hash: string): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.request(
				'delete',
				'configure/enquiry/' + enquiry_hash + '/quotes',
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/configure/e', enquiry_hash, 'authenticate']
		);
	}

	/**
	 * Creates a change against the enquiry.
	 *
	 * @param {string} enquiry_hash
	 * @param body
	 * @returns {Observable}
	 */
	createPaymentCharge(enquiry_hash: string, body: any, payment_type: 'buyNow' | 'reserve'): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.postOrPutRequest(
				'post',
				'configure/enquiry/' +
					enquiry_hash +
					'/steps/payment/' +
					(payment_type === 'buyNow' ? 'full' : 'reserve'),
				body,
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/configure/e', enquiry_hash, 'authenticate'],
			true
		);
	}

	/**
	 * Validates a payment
	 *
	 * @param {string} enquiry_hash
	 * @param body
	 * @returns {Observable}
	 */
	validatePayment(enquiry_hash: string, body: any): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.postOrPutRequest(
				'post',
				'configure/enquiry/' + enquiry_hash + '/steps/payment/validate',
				body,
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/configure/e', enquiry_hash, 'authenticate'],
			true
		);
	}

	generatePaymentToken(service: string, amount: number, enquiry_hash: string): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.postOrPutRequest(
				'post',
				'configure/enquiry/' + enquiry_hash + '/steps/payment/token',
				{
					payment: {
						service,
						amount,
					},
				},
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/configure/e/', enquiry_hash, 'authenticate'],
			true
		);
	}

	/**
	 * Creates or updates a callback action against the customer / enquiry.
	 *
	 * @param {string} enquiry_hash
	 * @param body
	 * @returns {Observable}
	 */
	saveCallback(enquiry_hash: string, body: any): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.postOrPutRequest(
				'post',
				'configure/enquiry/' + enquiry_hash + '/callback',
				body,
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/configure/e', enquiry_hash, 'authenticate']
		);
	}

	/**
	 * Saves customer details.
	 *
	 * @param {string} enquiry_hash
	 * @param body
	 * @returns {Observable}
	 */
	saveCustomer(enquiry_hash: string, body: any): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.postOrPutRequest(
				'put',
				'configure/enquiry/' + enquiry_hash + '/customer',
				body,
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/configure/e', enquiry_hash, 'authenticate']
		);
	}

	/**
	 * Saves meta information against the enquiry.
	 *
	 * @param {string} enquiry_hash
	 * @param body
	 * @returns {Observable}
	 */
	setMeta(enquiry_hash: string, body: any): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.postOrPutRequest(
				'put',
				'configure/enquiry/' + enquiry_hash + '/meta',
				body,
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/configure/e', enquiry_hash, 'authenticate']
		);
	}

	createCodeweaversProposalFromQuote(enquiry_hash: string, quote_uuid: string) {
		return this.api_utility_service.intercept(
			this.api_service.postOrPutRequest(
				'post',
				'configure/enquiry/' + enquiry_hash + '/generate-codeweavers-proposal',
				{
					quote_uuid: quote_uuid
				},
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/configure/e', enquiry_hash, 'authenticate']
		);
	}

	/**
	 * Sends tracking data to API.
	 */
	trackJourney(enquiry_hash: string, body: any): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.postOrPutRequest(
				'post',
				'core/journey-tracking',
				body,
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/configure/e', enquiry_hash, 'authenticate']
		);
	}

	/**
	 * Saves delivery options details.
	 */
	saveDeliveryOptions(enquiry_hash: string, body: any): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.postOrPutRequest(
				'put',
				'configure/enquiry/' + enquiry_hash + '/steps/delivery-options',
				body,
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/configure/e', enquiry_hash, 'authenticate']
		);
	}

	/**
	 * Mark calculation as submitted.
	 */
	markCalculationAsSubmitted(enquiry_hash: string, enq_finance_quotes_id: number): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.postOrPutRequest(
				'post',
				[
					'self-service',
					enquiry_hash,
					'proposals',
					enq_finance_quotes_id,
					'mark-recalculation-as-submitted',
				].join('/'),
				null,
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/finance-manager', enquiry_hash, 'authenticate']
		);
	}
}
